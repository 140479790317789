<template>
    <div class="content-wrap">
        <Header/>
        <div class="main">
            <div class="switch">
                <div class="action-box">
                    <label for="">填报功能</label>
                    <Switch v-model="valve" @on-change="change"/>
                </div>
                <p>{{ msg }}</p>

            </div>
            <div class="switch" style="border-top: none;padding-top: 0">
                <div class="action-box">
                    <label for="">填报年度</label>
<!--                    <Button class="" @click="change" size="" type="primary" format="yyyy">确认</Button>-->
                </div>
                <DatePicker type="year" placeholder="选择年份" style="width: 260px;margin-top: 10px" v-model="year"
                            size="large" @on-change="getDate"/>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script setup>
    import {ref, watch, inject, onMounted} from "vue";
    import Header from "./components/setupHeader.vue";
    import Footer from "@/components/footer.vue";

    let axios = inject("axios");
    let valve = ref();
    let msg = ref();
    let year = ref();
    watch(
        () => valve.value,
        (val) => {
            if (val) {
                msg.value = "已开启";
            } else {
                msg.value = "已关闭";
            }
        }
    );
    // 发送请求的函数
    const fetchStatusInfo = (year) => {
      const url = `/setting/fillState`;
      const params = {
        state: valve.value,
        year: typeof year === 'string' ? year : year.getFullYear(),
      };

      return axios.get(url, { params });
    };

    // 切换状态
    const change = (val) => {
      fetchStatusInfo(year.value).then((res) => {
        getStatusInfo(); // 简化，无论成功与否都调用
      });
    };

    // 获取日期
    const getDate = (val1, val2) => {
      fetchStatusInfo(val1).then((res) => {
        getStatusInfo(); // 简化，无论成功与否都调用
      });
    };
    // //切换状态
    // const change = (val) => {
    //     let url = `/setting/fillState`;
    //     let params = {
    //         state: valve.value,
    //     };
    //     if(typeof year.value === 'string'){
    //       params.year= year.value
    //     }else{
    //       params.year= year.value.getFullYear()
    //     }
    //     axios.get(url,{params}).then((res) => {
    //         if (res.data.code == 200) {
    //             getStatusInfo();
    //         } else {
    //             getStatusInfo();
    //         }
    //     });
    // };
    // const getDate=(val1,val2)=>{
    //   let url = `/setting/fillState`;
    //   let params = {
    //     state: valve.value,
    //     year: val1
    //   };
    //   axios.get(url,{params}).then((res) => {
    //     if (res.data.code == 200) {
    //       getStatusInfo();
    //     } else {
    //       getStatusInfo();
    //     }
    //   });
    // }

    //获取系统是否开启信息
    const getStatusInfo = () => {
        let url = `/setting/getFillState`;
        axios.get(url).then((res) => {
            if (res.data.code == 200) {
                let data = res.data.data;
                valve.value = data.state;
                year.value=data.year;
            } else {
                valve.value = false;
            }
        });
    };
    onMounted(() => {
        getStatusInfo();
    });
</script>

<style lang="scss" scoped>
    .main {
        height: calc(100vh - 110px);

        .switch {
            padding: 30px 0;
            border-top: 1px solid #888888;
            border-bottom: 1px solid #888888;
            margin-top: 30px;

            .action-box {
                display: flex;
                justify-content: space-between;
                align-items: center;

                label {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 20px;
                    letter-spacing: 0px;
                    color: #555555;
                    line-height: 30px;
                }

                :deep(.ivu-switch-checked) {
                    border-color: #688aea;
                    background-color: #688aea;
                }
            }

            > p {
                font-size: 18px;
                color: #111111;
                font-weight: normal;
                font-stretch: normal;
            }

            .btn-default {
                background-color: #688aea;
                border-radius: 6px;
                color: #fff;
                font-family: PingFangSC-Regular;
            }

            .btn-default:hover {
                background-color: #688aea;
                color: #fff;
                font-family: PingFangSC-Regular;
            }
        }
    }
</style>
